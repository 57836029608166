import OsirisService from './BaseOsirisService';

class RateService extends OsirisService {
    async extract() {
        try {
            return await this.api.get('/extract/user');
        } catch ({ response }) {
            return response;
        }
    }

    async extractByGroupId(groupId) {
        try {
            return await this.api.get(`/extract/group/${groupId}`);
        } catch ({ response }) {
            return response;
        }
    }

    async extractByGroupIdAndMonth(group, month) {
        try {
            return await this.api.get(`/extract/group/${group}/month/${month}`);
        } catch ({ response }) {
            return response;
        }
    }

    async extractByUserId(user) {
        try {
            return await this.api.get(`/extract/user/${user}`);
        } catch ({ response }) {
            return response;
        }
    }

    async update(id, data) {
        try {
            return await this.api.put(`/rate/user/${id}`, { data });
        } catch ({ response }) {
            return response;
        }
    }

    async ratesByGroupId(groupId) {
        try {
            return await this.api.get(`/rate/group/${groupId}`);
        } catch ({ response }) {
            return response;
        }
    }
}

export default new RateService();
